<template>
        <div>
            <p class="border-solid border-1 border-grey-light rounded p-3 overflow-y-scroll">
                {{ text }}
            </p>
            <vs-button 
                v-clipboard:copy="text"
                class="copy-button mt-4 xs:w-full sm:w-auto rounded-lg" >
                {{ $t("copy-to-clipboard.button.title") }}
            </vs-button>
        </div>
</template>
<script>
export default {
    name: 'copy-to-clipboard',
    data() {
        return {
            uid:  (Math.random() + 1).toString(36).substring(7),
        }
    },
    props: {
        text: {
            type: String,
            required: true
        }
    }
}
</script>