import axios from '@/axios.js'

export default {
  getPromoAssets : async (appUuid) => {
    try {
      const response = await axios.editorapi.get(`apps/v3/${appUuid}/promoassets`)
      return response.data.object
    } catch (error) {
        throw new Error(error)
    }
  }
}
