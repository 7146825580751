<template>
    <div class="vx-row w-full md:w-2/3 lg:w-1/2">
        <vx-card>
            <!-- Smart banner -->
            <div class="vx-row mt-3">
                <div class="vx-col w-full">
                    <h2 >{{ $t("promoassets.smartbanner.title") }}</h2>
                    <p class="mb-0 whitespace-pre-line">{{ $t("promoassets.smartbanner.subtitle") }}</p>
                </div>
            </div>

            <LabelForm>{{ $t("promoassets.smartbanner.language-select") }}:</LabelForm>
            <vs-select class="border-grey-light xs:w-full sm:w-1/4 mb-4" v-model="language">
                <vs-select-item :key="item.id" :value="item.acronym" :text="item.name" v-for="item in this.languages"/>
            </vs-select>
            <CopyToClipboard :text="smartBanner | htmlspecialchars_decode" />

            <!-- Smart link -->
            <div class="vx-row mt-16">
                <div class="vx-col w-full sm:w-full">
                    <h2 >{{ $t("promoassets.smart-link.title") }}</h2>
                    <p class="whitespace-pre-line">{{ $t("promoassets.smart-link.subtitle") }}</p>
                </div>
            </div>

            <CopyToClipboard :text="smartLink" />

            <!-- Promo assets -->
            <div class="vx-row mt-16" v-if="urlToDownload.length > 0">
                <div class="vx-col w-full sm:w-full">
                    <h2 >{{ $t("promoassets.title") }}</h2>
                    <p>{{ $t("promoassets.promo-assets.subtitle") }}</p>
                    <vs-button class="xs:w-full sm:w-auto rounded-lg" :href="urlToDownload">
                        {{ $t("promoassets.promo-assets.download") }}
                    </vs-button>
                </div>
            </div>



            <!-- Related articles -->
            <!-- <p class="mt-12">{{ $t("promoassets.related-articles.title") }}</p>
            <p class="mb-1"><a href="">{{ $t("promoassets.related-articles.how-to-promote") }}</a></p>
            <p><a href="">{{ $t("promoassets.elated-articles.how-to-use-smartlink") }}</a></p> -->

        </vx-card>
    </div>
</template>

<script>
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import CopyToClipboard from '@/modules/Shared/Components/CopyToClipboard'
import LanguageService from '@/modules/Shared/Services/LanguageService'
import PromoAssetsService from '@/modules/Apps/Services/PromoAssetsService'
import {mapGetters} from 'vuex'
export default {
    name: 'promo-asets',
    components: {
        LabelForm,
        CopyToClipboard
    },
    data() {
        return {
            language: null,
            languages: {},
            smartBanner: '',
            smartLink: '',
            urlToDownload: '',
        }
    },

    computed: {
        ...mapGetters( ['applicationUuid', 'applicationLanguage']),
        smartBannerWithLanguage(){
            if(this.smartBanner) {
               return 'exists smartbanner'
            } else {
                return 'not exists smartbanner'
            }
        }
    },
    async created() {
        this.language = this.applicationLanguage
        this.languages = await this.getLanguages()
        await this.getPromoAssets()
        this.changeSmartBannerLanguage()

    },
    watch: {
        language() {
            this.changeSmartBannerLanguage()
        }
    },
    methods: {
        changeSmartBannerLanguage() {
            this.smartBanner = this.$options.filters.htmlspecialchars_decode(this.smartBanner)
                                .replace(/smartbanner_({language}|\w{2})\.js">/, `smartbanner_${this.language}.js">`)
        },
        async getLanguages() {
            const supportedLanguages = ['en', 'es', 'fr', 'ca', 'it', 'de', 'pt', 'sv', 'sl', 'no', 'sk', 'pl']
            const languages = await LanguageService.getLanguages();
            return languages.filter(language => supportedLanguages.includes(language.acronym))
        },
        async getPromoAssets() {
            const promoAssets =  await PromoAssetsService.getPromoAssets(this.applicationUuid)

            this.smartBanner = promoAssets['smartbanner']
            this.smartLink = promoAssets['smartlink']
            this.urlToDownload = promoAssets['others'] ? promoAssets['others'] : this.urlToDownload
        }

    }

}
</script>
