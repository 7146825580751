var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "p",
        {
          staticClass:
            "border-solid border-1 border-grey-light rounded p-3 overflow-y-scroll"
        },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      ),
      _c(
        "vs-button",
        {
          directives: [
            {
              name: "clipboard",
              rawName: "v-clipboard:copy",
              value: _vm.text,
              expression: "text",
              arg: "copy"
            }
          ],
          staticClass: "copy-button mt-4 xs:w-full sm:w-auto rounded-lg"
        },
        [_vm._v(" " + _vm._s(_vm.$t("copy-to-clipboard.button.title")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }