var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-row w-full md:w-2/3 lg:w-1/2" },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row mt-3" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("promoassets.smartbanner.title")))
              ]),
              _c("p", { staticClass: "mb-0 whitespace-pre-line" }, [
                _vm._v(_vm._s(_vm.$t("promoassets.smartbanner.subtitle")))
              ])
            ])
          ]),
          _c("LabelForm", [
            _vm._v(
              _vm._s(_vm.$t("promoassets.smartbanner.language-select")) + ":"
            )
          ]),
          _c(
            "vs-select",
            {
              staticClass: "border-grey-light xs:w-full sm:w-1/4 mb-4",
              model: {
                value: _vm.language,
                callback: function($$v) {
                  _vm.language = $$v
                },
                expression: "language"
              }
            },
            _vm._l(this.languages, function(item) {
              return _c("vs-select-item", {
                key: item.id,
                attrs: { value: item.acronym, text: item.name }
              })
            }),
            1
          ),
          _c("CopyToClipboard", {
            attrs: { text: _vm._f("htmlspecialchars_decode")(_vm.smartBanner) }
          }),
          _c("div", { staticClass: "vx-row mt-16" }, [
            _c("div", { staticClass: "vx-col w-full sm:w-full" }, [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("promoassets.smart-link.title")))
              ]),
              _c("p", { staticClass: "whitespace-pre-line" }, [
                _vm._v(_vm._s(_vm.$t("promoassets.smart-link.subtitle")))
              ])
            ])
          ]),
          _c("CopyToClipboard", { attrs: { text: _vm.smartLink } }),
          _vm.urlToDownload.length > 0
            ? _c("div", { staticClass: "vx-row mt-16" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full sm:w-full" },
                  [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("promoassets.title")))]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("promoassets.promo-assets.subtitle"))
                      )
                    ]),
                    _c(
                      "vs-button",
                      {
                        staticClass: "xs:w-full sm:w-auto rounded-lg",
                        attrs: { href: _vm.urlToDownload }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("promoassets.promo-assets.download")
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }